.services-section{
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 850px;
  position: relative;
  z-index: 1;
  flex-direction: column;
  }

  .services-section:before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), 
        linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
        z-index: 2;
    }
  
  .services-heading{
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin-bottom: 2rem;
    padding-top: 3rem;
    z-index: 3;
  }
  
  .services{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    transform: translateY(80px);
    padding: 0;
    height: auto;
    z-index: 3;
  }
  
  .services-cell{
    flex: 0 1 250px;
    max-width: 250px;
    height: 275px;
    margin: 2px;
    position: relative;
    text-align: center;
    z-index: 3;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.8);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    cursor: pointer;
  }
  
  .services-cell_img{
    object-fit: cover;
    object-position: center;
  }
  
  .services-cell_text{
    height: 100%;
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    transition: opacity 350ms;
    text-shadow: 2px 2px 2px #212329;
  }
  
  .services-cell::before, .services-cell::after, .services-cell_img{
    top: -50%;
    width: 100%;
    height: 200%;
    display: block;
    position: absolute;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    z-index: -1;
  }
  
  .services-cell:hover{
    opacity: 1;
    transition: all 0.3s ease-in-out;
    transform: scale(1.2);
    z-index: 99;
    overflow: hidden;
  }
  
  .services-cell:before{
    content: '';
    position: absolute;
    opacity: 0.4;
    width: 350px;
    height: 70px;
    background: #fff;
    top: 50;
    left: 0;
    z-index: 1;
    transform: rotate(45deg);
    transition: transform 1.5s;
  }
  
  .services-cell:hover:before{
    transform: translate(-100px, 600%) rotate(45deg);
    transition: transform 0.5s;
  }
  
  @media screen and (min-width: 550px) and (max-width: 825px) {
    .services-cell:nth-child(3n){
      margin-right: calc(50% - 125px);
      margin-left: calc(50% - 125px);
    }
  
    .services-cell:nth-child(3) {
      top: -70px;
    }
  
    .services-cell:nth-child(4) {
      top: -140px;
    }
  
    .services-cell:nth-child(5) {
      top: -140px;
    }
  
    .services-cell:nth-child(6) {
      top: -210px;
    }
  
    .services-cell:nth-child(7) {
      top: -280px;
      margin-right: 250px;
    }
  }
  
  @media screen and (min-width: 825px) and (max-width: 1100px) {
    .services-cell:nth-child(4) {
      top: -70px;
      margin-left: calc(50% - 275px);
    }
    .services-cell:nth-child(5) {
      top: -70px;
      margin-right: calc(50% - 275px);
      position: relative;
    }
    .services-cell:nth-child(6) {
      top: -140px;
      margin-left: calc(50% - 530px);
    }
    .services-cell:nth-child(7) {
      top: -140px;
      margin-right: calc(50% - 275px);
    }
  }
  
  @media screen and (min-width: 1100px) {
    .services-cell:nth-child(n+5){
      position: relative;
      top: -70px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .services-cell {
      margin: 50px 25px;
    }
  }

  @media screen and (max-width: 1000px) {
    .services-section{
    padding: 100px 0;
    height: 1400px;
    }
}
  @media screen and (max-width: 768px) {
    .services-section{
    padding: 100px 0;
    height: 3000px;
    }
}